import { View } from "../View";

export default class Waiting extends View {
    render() {
        return (
            <div className="Waiting">
                <h2>Veuillez patienter</h2>
                <hr />
                <div className="spinner"></div>
            </div>
        );
    }
}
