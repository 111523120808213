import { View } from "../View";

export default class Q10 extends View {
    render() {
        return (
            <div className="Q10">
                <div className="vcenter-text longtext">
                    Les femmes hurlez pour faire du mal au sujet féminin ! <br /> <br />
                    Les hommes hurlez pour faire du mal au sujet masculin ! <br /><br />
                </div>
            </div>
        );
    }
}
