import React from 'react';

import Home from './components/Home/Home';
import End from "./components/End/End";
import Waiting from "./components/Waiting/Waiting";

import "./App.css";
import bg_top from './assets/background/bg_top.png';
import bg_center from './assets/background/bg_center.png';
import bg_bottom from './assets/background/bg_bottom.png';
import no_signal from './assets/background/no_signal.png';
import MainService from './services/MainService';
import { AlterResponse, AlterView } from './models/AlterView';
import Q1 from './components/Q1/Q1';
import Q2_1 from './components/Q2/Q2_1';
import Q2_2 from './components/Q2/Q2_2';
import Q3_1 from './components/Q3/Q3_1';
import Q3_2 from './components/Q3/Q3_2';
import Q3_3 from './components/Q3/Q3_3';
import Q3_4 from './components/Q3/Q3_4';
import Q3_5 from './components/Q3/Q3_5';
import Q3_6 from './components/Q3/Q3_6';
import Q4_1 from './components/Q4/Q4_1';
import Q4_2 from './components/Q4/Q4_2';
import Q4_3 from './components/Q4/Q4_3';
import Q4_4 from './components/Q4/Q4_4';
import Q4_5 from './components/Q4/Q4_5';
import Q5 from './components/Q5_12/Q5';
import Q10 from './components/Q5_12/Q10';
import Q9 from './components/Q5_12/Q9';
import Q8 from './components/Q5_12/Q8';
import Q6 from './components/Q5_12/Q6';
import Q7 from './components/Q5_12/Q7';
import Q11 from './components/Q5_12/Q11';
import Q12 from './components/Q5_12/Q12';

interface AppState {
    previousView: AlterView | null,
    currentView: AlterView,
    currentPayload?: string,
    userToken: string,
    intervalId: NodeJS.Timeout | null,
    q3Age?: string,
    q3Pseudo?: string;
}

export default class App extends React.Component<{}, AppState> {
    state: AppState;
    views: Map<AlterView, {viewElement: JSX.Element, next: AlterView | null}> = new Map<AlterView, {viewElement: JSX.Element, next: AlterView | null}>([
        [AlterView.WAITING, {viewElement: <Waiting onFinish={() => {}} />, next: null}],
        [AlterView.HOME, {viewElement: <Home onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q1, {viewElement: <Q1 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q2_1, {viewElement: <Q2_1 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q2_2, {viewElement: <Q2_2 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q3_1, {viewElement: <Q3_1 onFinish={this.viewFinished.bind(this)} />, next: AlterView.Q3_2}],
        [AlterView.Q3_2, {viewElement: <Q3_2 onFinish={this.viewFinished.bind(this)} />, next: AlterView.Q3_3}],
        [AlterView.Q3_3, {viewElement: <Q3_3 onFinish={this.viewFinished.bind(this)} />, next: AlterView.Q3_4}],
        [AlterView.Q3_4, {viewElement: <Q3_4 onFinish={this.viewFinished.bind(this)} />, next: AlterView.Q3_5}],
        [AlterView.Q3_5, {viewElement: <Q3_5 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q3_6, {viewElement: <Q3_6 onFinish={this.viewFinished.bind(this)} payload={this.getPayload.bind(this)} />, next: null}],
        [AlterView.Q4_1, {viewElement: <Q4_1 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q4_2, {viewElement: <Q4_2 onFinish={this.viewFinished.bind(this)} />, next: AlterView.Q4_3}],
        [AlterView.Q4_3, {viewElement: <Q4_3 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q4_4, {viewElement: <Q4_4 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q4_5, {viewElement: <Q4_5 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q5, {viewElement: <Q5 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q6, {viewElement: <Q6 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q7, {viewElement: <Q7 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q8, {viewElement: <Q8 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q9, {viewElement: <Q9 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q10, {viewElement: <Q10 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q11, {viewElement: <Q11 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.Q12, {viewElement: <Q12 onFinish={this.viewFinished.bind(this)} />, next: null}],
        [AlterView.END, {viewElement:<End onFinish={() => {}} />, next: null}],
    ]);
    autoNextViews: Map<AlterView, AlterView[]> = new Map<AlterView, AlterView[]>([
        [AlterView.Q3_1, [AlterView.Q3_2, AlterView.Q3_3, AlterView.Q3_4, AlterView.Q3_5]],
        [AlterView.Q4_2, [AlterView.Q4_3]]
    ]);

    constructor(props: {}) {
        super(props);

        this.update = this.update.bind(this);

        this.state = {
            previousView: null,
            currentView: AlterView.Q7,
            userToken: MainService.generateToken(),
            intervalId: null
        };
    }

    componentDidMount() {
        this.update();

        let intervalId = setInterval(this.update, 10000);
        this.setState({intervalId: intervalId});
    }

    componentWillUnmount() {
        if(this.state.intervalId) {
            clearInterval(this.state.intervalId);
            this.setState({intervalId: null});
        }
    }

    private update() {
        MainService.fetchManager()
            .then(manager => {
                if(this.state.previousView === manager.currentView || this.state.currentView === manager.currentView)
                    return;
                
                if(this.autoNextViews.get(manager.currentView)?.includes(this.state.currentView))
                    return;

                if(this.state.previousView !== null && this.autoNextViews.get(manager.currentView)?.includes(this.state.previousView))
                    return;

                this.setState({currentView: manager.currentView, currentPayload: manager.payload});
            })
            .catch(error => {
                console.log(error);
            });
    }

    viewFinished(answer: AlterResponse | string | null) {
        if(answer !== null && answer !== undefined) {
            if (this.state.currentView === AlterView.Q3_1) {
                this.setState({q3Age: answer as string});
            } else if(this.state.currentView === AlterView.Q3_2) {
                this.setState({q3Pseudo: answer as string});
            } else if(this.state.currentView === AlterView.Q3_3) {
                let givenName = answer as string;
                if(this.state.q3Age != null && this.state.q3Pseudo != null && givenName != null) {
                    let comment = `Age: ${this.state.q3Age} - Pseudo: ${this.state.q3Pseudo} - Nom donné: ${givenName}`;

                    MainService.submitComment({
                        view: this.state.currentView,
                        userToken: this.state.userToken,
                        content: comment
                    }).catch(error => {
                        console.log(error);
                    });
                }
            } else {
                MainService.submitVote({
                    view: this.state.currentView,
                    userToken: this.state.userToken,
                    answer: answer as AlterResponse
                }).catch(error => {
                    console.log(error);
                });
            }
        }

        this.setState({
            previousView: this.state.currentView,
            currentView: this.views.get(this.state.currentView)?.next ?? AlterView.WAITING
        });
    }

    getPayload() {
        return this.state.currentPayload;
    }

    renderBackground() {
        if(this.state.currentView === AlterView.Q11) {
            return (<div className="background bsod"></div>);
        } else if(this.state.currentView === AlterView.Q12) {
            return (
                <div className="background bsod">
                    <img className="bg-fill" src={no_signal} alt="background top" />
                </div>
            );
        } else {
            return (
                <div className="background">
                    <img className="bg-top" src={bg_top} alt="background top" />
                    <img className="bg-center" src={bg_center} alt="background center" />
                    <img className="bg-bottom" src={bg_bottom} alt="background bottom" />
                </div>
            );
        }
    }

    render() {
        return (
            <div className="App">
                { this.renderBackground() }
                <div className="content">
                    {this.views.get(this.state.currentView)?.viewElement}
                </div>
            </div>
        );
    }
}
