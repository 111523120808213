export enum AlterView {
    WAITING = "waiting",
    HOME = "home",
    Q1 = "q1",

    Q2_1 = "q2_1",
    Q2_2 = "q2_2",
    
    Q3_1 = "q3_1",
    Q3_2 = "q3_2",
    Q3_3 = "q3_3",
    Q3_4 = "q3_4",
    Q3_5 = "q3_5",
    Q3_6 = "q3_6",

    Q4_1 = "q4_1",
    Q4_2 = "q4_2",
    Q4_3 = "q4_3",
    Q4_4 = "q4_4",
    Q4_5 = "q4_5",

    Q5 = "q5",
    Q6 = "q6",
    Q7 = "q7",
    Q8 = "q8",
    Q9 = "q9",
    Q10 = "q10",
    Q11 = "q11",
    Q12 = "q12",
    END = "end"
}

export enum AlterResponse {
    NO = 0,
    YES = 1,

    SUBJECT_1 = 0,
    SUBJECT_2 = 1,
    MACHINE = 2,
    BOTH = 2,
    NEITHER = 2
}