import { Manager } from "../models/Manager";
import { AlterView } from "../models/AlterView";
import { Vote } from "../models/Vote";
import { Comment } from "../models/Comment";

export default class MainService {
    private static endpoint = "api.php/";
    //private static endpoint = "http://localhost:8080/api.php/";

    static generateToken() {
        const rand = () => Math.random().toString(36).substring(2);
        const token = (length: number) => (rand() + rand()).substring(0, length);

        return token(30);
    }

    static async fetchManager(): Promise<Manager> {
        let requestInit: RequestInit = {
            method: "GET",
            mode: "cors"
        };

        return fetch(MainService.endpoint + "status/view", requestInit)
            .then((response) => response.json())
            .then((response) => ({
                managerId: response.managerId,
                currentView: response.currentView as AlterView,
                payload: response.payload
            } as Manager));
    }

    static async submitVote(vote: Vote) {
        let requestInit: RequestInit = {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(vote),
        };

        fetch(MainService.endpoint + "votes", requestInit);
    }

    static async submitComment(comment: Comment) {
        let requestInit: RequestInit = {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(comment),
        };

        fetch(MainService.endpoint + "comments", requestInit);
    }
}
