import { View } from "../View";

export default class Q8 extends View {
    render() {
        return (
            <div className="Q8">
                <h2>Mise à jour d'Alter</h2>
                <hr />
                <div className="spinner"></div>
            </div>
        );
    }
}
