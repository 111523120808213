import { View } from "../View";

export default class End extends View {
    render() {
        return (
            <div className="End">
                <h2>Fin</h2>
                <hr />
                <div className="end-text">Merci de votre participation !</div>
            </div>
        );
    }
}
