import { View } from "../View";

export default class Q3_4 extends View {
    render() {
        return (
            <div className="Q3_4">
                <h2>Je m'appelle la Machine</h2>
                <hr />
                <div className="btn-group">
                    <button onClick={ () => this.props.onFinish() }>Ok</button>
                </div>
            </div>
        );
    }
}
