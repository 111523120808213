import { AlterResponse } from "../../models/AlterView";
import { View } from "../View";

export default class Q5 extends View {
    render() {
        return (
            <div className="Q5">
                <h2>C'est l'heure du combat !<br />Qui voulez-vous voir gagner ?</h2>
                <hr />
                <div className="btn-group">
                    <button onClick={ () => this.props.onFinish(AlterResponse.SUBJECT_1) }>Le sujet féminin</button>
                    <button onClick={ () => this.props.onFinish(AlterResponse.SUBJECT_2) }>Le sujet masculin</button>
                </div>
            </div>
        );
    }
}
