import { AlterResponse } from "../../models/AlterView";
import { View } from "../View";

export default class Q4_5 extends View {
    render() {
        return (
            <div className="Q4_5">
                <h2>Qui voulez-vous accélérer ?</h2>
                <hr />
                <div className="btn-group btn-group-3">
                    <button onClick={ () => this.props.onFinish(AlterResponse.SUBJECT_1) }>Le sujet féminin</button>
                    <button onClick={ () => this.props.onFinish(AlterResponse.SUBJECT_2) }>Le sujet masculin</button>
                    <button onClick={ () => this.props.onFinish(AlterResponse.BOTH) }>Les deux</button>
                </div>
            </div>
        );
    }
}
