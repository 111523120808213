import { View } from "../View";

export default class Q2_2 extends View {
    render() {
        return (
            <div className="Q2_2">
                <div className="vcenter-text">Vous avez choisi le sujet féminin</div>
                <hr />
            </div>
        );
    }
}
