import { AlterResponse } from "../../models/AlterView";
import { View } from "../View";

export default class Q6 extends View {
    render() {
        return (
            <div className="Q6">
                <h2>Êtes-vous sûrs de vouloir voir le sujet féminin gagner ?</h2>
                <hr />
                <div className="btn-group">
                    <button onClick={ () => this.props.onFinish(AlterResponse.YES) }>Oui</button>
                    <button onClick={ () => this.props.onFinish(AlterResponse.NO) }>Non</button>
                </div>
            </div>
        );
    }
}
