import { View } from "../View";

export default class Q11 extends View {
    render() {
        return (
            <div className="Q11">
                <div className="q11-top">
                    An error has occured<br />
                    0x083AF3667D<br />
                    0x0AFAF7D765<br />
                    0x0EE897D550<br />
                </div>
                <div className="q11-middle">
                    010010101001<br />
                    0LOW0101010<br />
                    10101BATTERY<br />
                    10101000101<br />
                </div>
                <div className="q11-bottom">PLEASE REBOOT THE SYSTEM</div>
            </div>
        );
    }
}
