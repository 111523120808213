import { AlterResponse } from "../../models/AlterView";
import { View } from "../View";

export default class Q1 extends View {
    render() {
        return (
            <div className="Q1">
                <h2>Êtes-vous humain ?</h2>
                <hr />
                <div className="btn-group">
                    <button onClick={ () => this.props.onFinish(AlterResponse.YES) }>Oui</button>
                    <button onClick={ () => this.props.onFinish(AlterResponse.NO) }>Non</button>
                </div>
            </div>
        );
    }
}
