import { View } from "../View";

export default class Q3_6 extends View {
    render() {
        let voteResult = this.props.payload?.() ?? "_0";
        let voteText = "";
        switch (voteResult) {
            case "_0": voteText = "le sujet féminin"; break;
            case "_1": voteText = "le sujet masculin"; break;
            default: voteText = "la machine"; break;
        }

        return (
            <div className="Q3_6">
                <div className="vcenter-text longtext">
                    Vous avez choisi {voteText}. <br /> <br />
                    Pour le sauver de la jalousie de l'autre sujet, faites du bruit ! <br /><br />
                    <span className="big">PLUS DE BRUIT !!!</span>
                </div>
            </div>
        );
    }
}
