import { View, ViewState } from "../View";

interface Q3State extends ViewState {
    fieldContent: string
}

export default class Q3_2 extends View<Q3State> {
    render() {
        return (
            <div className="Q3_2">
                <h2>Quel est votre <br /> pseudonyme ?</h2>
                <hr />
                <div className="btn-group">
                    <input type="text" maxLength={20} onChange={e => this.setState({fieldContent: e.target.value})} />
                    <button onClick={ () => this.props.onFinish(this.state.fieldContent) }>Valider</button>
                </div>
            </div>
        );
    }
}
