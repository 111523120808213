import { View } from "../View";

import "./Home.css";
import logo from "../../assets/logo.png";

export default class Home extends View {
    render() {
        return (
            <div className="Home">
                <h1>Alter</h1>
                <span className="subtitle">par</span>
                <img src={logo} alt="Dynamina logo" className="logo" />
                <button onClick={ () => this.props.onFinish() }>Commencer</button>
                <span className="subbutton">
                    Merci de votre participation !
                </span>
            </div>
        );
    }
}
