import React from "react";

export interface ViewProps {
    onFinish: Function,
    payload?: Function;
}

export interface ViewState {
    
}

export class View<State = ViewState> extends React.Component<ViewProps, State> {

}
