import { View, ViewProps, ViewState } from "../View";

import "../Subjects.css";
import subject1_fig from "../../assets/subject1.png";
import subject2_fig from "../../assets/subject2.png";
import machine_fig from "../../assets/machine.png";
import star_empty from "../../assets/icons/star_empty.svg";
import star_filled from "../../assets/icons/star_filled.svg";

interface Q4State extends ViewState {
    subject1Score: number,
    subject2Score: number,
    machineScore: number
}

export default class Q4_1 extends View<Q4State> {
    constructor(props: ViewProps) {
        super(props);

        this.state = {
            subject1Score: -1,
            subject2Score: -1,
            machineScore: -1
        };

        this.starClicked = this.starClicked.bind(this);
    }

    starClicked(subject: string, score: number) {
        if(subject === "subject1")
            this.setState({subject1Score: score});
        else if(subject === "subject2")
            this.setState({subject2Score: score});
        else if(subject === "machine")
            this.setState({machineScore: score});
        else
            throw new Error("Invalid subject");
    }

    generateIconList(subject: string, score: number) {
        let notes = [1, 2, 3, 4, 5];

        return notes.map((note) => (
            <div className="icon-container" key={note} onClick={ () => this.starClicked(subject, note) }>
                <img src={note > score ? star_empty : star_filled} alt="star icon" />
            </div>
        ));
    }

    render() {
        return (
            <div className="Subjects">
                <h2>Notez les sujets</h2>
                <hr />
                <div className="subjects-content">
                    <div className="subject">
                        <img
                            className="fig"
                            src={subject1_fig}
                            alt="subject figure"
                        />
                        <div className="name">Sujet féminin</div>
                        <div className="icons">
                            {this.generateIconList("subject1", this.state.subject1Score)}
                        </div>
                    </div>
                    <div className="subject">
                        <img
                            className="fig"
                            src={subject2_fig}
                            alt="subject figure"
                        />
                        <div className="name">Sujet masculin</div>
                        <div className="icons">
                            {this.generateIconList("subject2", this.state.subject2Score)}
                        </div>
                    </div>
                    <div className="subject">
                        <img
                            className="fig"
                            src={machine_fig}
                            alt="subject figure"
                        />
                        <div className="name">La Machine</div>
                        <div className="icons">
                            {this.generateIconList("machine", this.state.machineScore)}
                        </div>
                    </div>
                    <button
                        onClick={ () => this.props.onFinish(this.state.subject1Score + 10* this.state.subject2Score + 100*this.state.machineScore) }
                        disabled={ this.state.subject1Score < 0 || this.state.subject2Score < 0 || this.state.machineScore < 0 }
                    >
                        Valider
                    </button>
                </div>
            </div>
        );
    }
}
