import { AlterResponse } from "../../models/AlterView";
import { View } from "../View";

export default class Q3_5 extends View {
    render() {
        return (
            <div className="Q3_5">
                <h2>Qui préférez-vous ?</h2>
                <hr />
                <div className="btn-group">
                    <button onClick={ () => this.props.onFinish(AlterResponse.SUBJECT_1) }>Le sujet féminin</button>
                    <button onClick={ () => this.props.onFinish(AlterResponse.SUBJECT_2) }>Le sujet masculin</button>
                    {/* <button onClick={ () => this.props.onFinish(AlterResponse.MACHINE) }>La Machine</button> */}
                </div>
            </div>
        );
    }
}
