import { View } from "../View";

export default class Q12 extends View {
    render() {
        return (
            <div className="Q12">
            </div>
        );
    }
}
